import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Breadcrumbs,
  TextField,
  Alert,
  Grid,
  OutlinedInput,
  Checkbox,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate, Link } from 'react-router-dom';
import UserFormEdit from '../../components/Users/UserFormEdit';
import { useGet, usePut, usePost } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../../styles/Avatar/style.css';
import 'react-quill/dist/quill.snow.css';

const BannerAdd = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const postU = usePost();
  const putU = usePut();

  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);

  const [uploadedBanner, setUploadedBanner] = useState(
    '/static/images/defphoto.jpg'
  );
  const [uploadedMainImg, setUploadedMainImg] = useState(
    '/static/images/defphoto.jpg'
  );
  const [values, setValues] = useState({
    title: '',
    city_id: [],
    url: ''
  });
  const [errors, setErrors] = useState({
    title: false,
    city_id: false,
    url: false
  });

  const [cities, setCities] = useState([]);
  const [choosenCities, setChoosenCities] = useState([]);
  const [selectCitys, setSelectCitys] = useState({});

  const [alert, setAlert] = useState({
    txt: '',
    type: 'error',
    number: 0
  });

  const handleChange = (event, name) => {
    setNothingChanged(false);
    setValues({
      ...values,
      [name]: event.target.value
    });
    setErrors({
      ...errors,
      [name]: false
    });
  };

  const handleChangeCity = (event) => {
    const value = event.target.value;
    setChoosenCities(value);
    setValues({
      ...values,
      city_id: value?.map((el) => cities?.find((elem) => elem.name === el)?.id)
    });
    setErrors({
      ...errors,
      city_id: false
    });
  };

  const changeValuesLinks = (e, id) => {
    const value = e.target.value;
    setSelectCitys((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, value, error: false } : item
      )
    );
  };

  const imgUploaded = (event) => {
    setNothingChanged(false);
    setUploadedBanner(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      mobile_img: event.target.files[0]
    });
  };

  const mainImgUploaded = (event) => {
    setNothingChanged(false);
    setUploadedMainImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      desktop_img: event.target.files[0]
    });
  };

  const showAlert = (number, type, text) => {
    setAlert({
      txt: text,
      type,
      number
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        number: 0
      });

      setSubmitDisabled(false);
    }, 2500);
  };

  const clearForm = () => {
    setValues((prev) => ({
      ...prev,
      title: '',
      url: '',
      mobile_img: '',
      desktop_img: ''
    }));
    setSelectCitys((prev) => prev.map((item) => ({ ...item, value: '' })));
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.title === '') {
      validComplete = false;
      formErrors.title = false;
      showAlert(3, 'error', 'Введите название баннера');
    }
    if (values.city_id.length === 0) {
      validComplete = false;
      formErrors.city_id = false;
      showAlert(
        3,
        'error',
        'Поле Массив связных городов не должно быть пустым'
      );
    }

    const urlRegex = /^(http|https)/;

    setSelectCitys((prev) =>
      prev.map((item) =>
        item.value === 0 || !urlRegex.test(item.value)
          ? { ...item, error: true }
          : item
      )
    );

    selectCitys.forEach((item) => {
      if (item.value.length === 0 && values.city_id.includes(item.id)) {
        validComplete = false;
        showAlert(3, 'error', 'Поле ссылка не должна быть пустым');
      } else if (item.value.length > 0 && !urlRegex.test(item.value)) {
        validComplete = false;
        showAlert(3, 'error', 'Введите корректную ссылку');
      }
    });

    setErrors(formErrors);
    return validComplete;
  };

  const submitAvatar = async (id) => {
    if (nothingChanged) {
      showAlert(1, 'error', 'Нет изменений');
      return;
    }
    setSubmitDisabled(true);

    let data = new FormData();
    data.append('img', values.mobile_img);

    putU(`banners/mobile/${id}`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert(3, 'success', 'Данные успешно добавлены');
          clearForm();
        } else {
          showAlert(3, 'error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert(3, 'error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submitMainImg = async (id) => {
    if (nothingChanged) {
      showAlert(2, 'error', 'Нет изменений');
      return;
    }
    setSubmitDisabled(true);

    let data = new FormData();
    data.append('img', values.desktop_img);

    putU(`banners/desktop/${id}`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert(2, 'success', 'Данные успешно обновленны');
        } else {
          showAlert(2, 'error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert(2, 'error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submit = async () => {
    if (nothingChanged) {
      showAlert(1, 'error', 'Нет изменений');
      return;
    }
    const isValid = validate();

    if (!isValid) return;

    setSubmitDisabled(true);

    const urls = selectCitys
      .map(
        (item) =>
          values.city_id.includes(item.id) && {
            city_id: item.id,
            url: item.value
          }
      )
      .filter((item) => item);

    const data = {
      title: values.title,
      city_id: values.city_id,
      urls
    };

    postU(`banners`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          submitMainImg(resp.data.banner.id);
          submitAvatar(resp.data.banner.id);
          showAlert(3, 'success', 'Данные успешно добавлены');
        } else {
          showAlert(1, 'error', 'Ошибка');
        }
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || 'Произошла ошибка';
        showAlert(3, 'error', errorMessage);
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  useEffect(() => {
    setIsLoaded(true);
    getU(`city`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCities(resp.data.city);
          const selectList = [];
          resp.data.city.forEach((item) => {
            selectList.push({
              name: item.name,
              id: item.id,
              error: false,
              value: '',
              show: false
            });
          });
          setSelectCitys(selectList);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Добавление баннера</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 14 }}>
            <Link underline="hover" color="inherit" to="/app/banners">
              Баннера
            </Link>
            <p>Добавление баннера</p>
          </Breadcrumbs>
        </Container>
      </Box>

      {/*preview*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.number === 1 ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Добавление мобильного изображения" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div className="container">
                      <input
                        accept="xlsx/*"
                        type="file"
                        style={{ display: 'none' }}
                        id={1}
                        onChange={(event) => imgUploaded(event, 1)}
                      />
                      <label htmlFor={1}>
                        <img src={uploadedBanner} className="itemImg" />
                        <div className="middle" />
                      </label>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png .jpg .svg .bmp .tga
                      .webp
                    </div>
                  </div>
                </CardContent>
                <Divider />
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
      {/*main_img*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.number === 2 ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Добавление десктопного изображения" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div className="container">
                      <input
                        accept="xlsx/*"
                        type="file"
                        style={{ display: 'none' }}
                        id={2}
                        onChange={(event) => mainImgUploaded(event, 1)}
                      />
                      <label htmlFor={2}>
                        <img src={uploadedMainImg} className="itemMainImg" />
                        <div className="middle" />
                      </label>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png .jpg .svg .bmp .tga
                      .webp
                    </div>
                  </div>
                </CardContent>
                <Divider />
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      {/*info*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Баннер" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Название баннера"
                    margin="normal"
                    name="title"
                    onChange={(event) => handleChange(event, 'title')}
                    type="text"
                    value={values.title}
                    variant="outlined"
                    error={errors.title}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="Города">Города</InputLabel>
                    <Select
                      label="Города"
                      name="city_id"
                      multiple
                      value={choosenCities}
                      onChange={handleChangeCity}
                      input={<OutlinedInput label="Города" />}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {cities?.map((city) => (
                        <MenuItem key={city.name} value={city.name}>
                          <Checkbox
                            checked={choosenCities?.indexOf(city.name) > -1}
                          />
                          <ListItemText primary={city.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectCitys.length > 0 &&
                    selectCitys.map((city) => {
                      return (
                        values.city_id.includes(city.id) && (
                          <TextField
                            key={city.id}
                            fullWidth
                            label={`Ссылка для ${city.name}`}
                            margin="normal"
                            name="url"
                            onChange={(event) =>
                              changeValuesLinks(event, city.id)
                            }
                            type="textarea"
                            multiline
                            value={city.value}
                            variant="outlined"
                            error={city.error}
                          />
                        )
                      );
                    })}
                  <Alert
                    severity={alert.type}
                    style={{ display: alert.number === 3 ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BannerAdd;
