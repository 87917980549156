import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent,
  Breadcrumbs,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useGet, usePost } from '../../API/request';
import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { useEffect } from 'react';
import { BallTriangle } from 'react-loader-spinner';

const SeoPagesAdd = () => {
  const navigate = useNavigate();
  const postU = usePost();
  const getU = useGet();
  const [isLoaded, setIsLoaded] = useState(false);
  const [cityList, setCitys] = useState([]);
  const [choosenCity, setChoosenCity] = useState('');

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [values, setValues] = useState({
    name: '',
    param: 'Index',
    img: '',
    description: '',
    url: '',
    city_id: '',
    schema: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    param: false,
    img: false,
    description: false,
    url: false,
    city_id: false,
    schema: false,
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const getSitysList = () => {
    const url = 'city';

    setIsLoaded(true);

    getU(url)
      .then((resp) => {
        if (resp.status === 'success') {
          setCitys(resp.data.city);
        } else {
          throw resp.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          showAlert(
            'error',
            'Произошла ошибка при загрузке городов, попробуйте перезайти'
          )
        )
      )
      .finally(() => setIsLoaded(false));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false
    }));
  };

  const handleChangeCity = (event) => {
    const value = event.target.value;
    setChoosenCity(value);
    setValues({
      ...values,
      city_id: cityList?.find((elem) => elem.name === value)?.id
    });
    setErrors({
      ...errors,
      city_id: false
    });
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    const requiredFields = [
      { field: 'name', errorMessage: 'Введите заголовок' },
      { field: 'url', errorMessage: 'Введите URL' },
      { field: 'description', errorMessage: 'Добавьте описание' },
      { field: 'city_id', errorMessage: 'Выберите город' },
      { field: 'schema', errorMessage: 'Добавьте схему' },
    ];

    requiredFields.forEach(({ field, errorMessage }) => {
      if (values[field] === '') {
        validComplete = false;
        formErrors[field] = true;
        showAlert('error', errorMessage);
      }
    });

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      name: '',
      param: '',
      img: '',
      description: '',
      url: '',
      city_id: '',
      schema: '',
    });
    setChoosenCity('');
  };

  const submit = async () => {
    if (validate()) {
      setSubmitDisabled(true);

      const payload = {
        name: values.name,
        description: values.description,
        url: values.url,
        city_id: Number(values.city_id),
        schema: values.schema,
      };

      if (values.img.length > 0) {
        payload.img = values.img;
      }

      postU('seo_url', payload)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'SEO добавлен');
            clearForm();
          } else {
            throw resp.message;
          }
        })
        .catch((err) => {
          showAlert('error', `Ошибка сервера: ${err}`);
        })
        .finally(() => setSubmitDisabled(false));
    }
  };

  useEffect(() => {
    getSitysList();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Добавить SEO страниц</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 14 }}>
            <RouterLink underline="hover" color="inherit" to="/app/seo-pages">
              SEO страниц
            </RouterLink>
            <p>Добавить SEO страниц</p>
          </Breadcrumbs>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавление SEO страниц" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    <FormControl fullWidth sx={{ mr: 3, width: '70%' }}>
                      <InputLabel id="Город">Город</InputLabel>
                      <Select
                        error={errors.city_id}
                        label="Город"
                        name="city_id"
                        value={choosenCity}
                        onChange={handleChangeCity}
                        input={<OutlinedInput label="Город" />}
                      >
                        {cityList?.map((city) => (
                          <MenuItem key={city.name} value={city.name}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ mr: 3, width: '70%' }}
                      label="URL"
                      fullWidth
                      margin="normal"
                      name="url"
                      onChange={handleChange}
                      type="text"
                      value={values.url}
                      variant="outlined"
                      error={errors.url}
                    />
                    <TextField
                      sx={{ mr: 3, width: '70%' }}
                      label="Заголовок"
                      fullWidth
                      margin="normal"
                      name="name"
                      onChange={handleChange}
                      type="text"
                      value={values.name}
                      variant="outlined"
                      error={errors.name}
                    />
                    <TextField
                      sx={{ mr: 3, width: '70%' }}
                      fullWidth
                      label="Описание"
                      margin="normal"
                      name="description"
                      onChange={handleChange}
                      type="text"
                      value={values.description}
                      error={errors.description}
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                    <TextField
                      sx={{ mr: 3, width: '70%' }}
                      label="Схема"
                      fullWidth
                      margin="normal"
                      name="schema"
                      onChange={handleChange}
                      type="text"
                      value={values.schema}
                      variant="outlined"
                      error={errors.schema}
                      multiline
                      rows={6}
                    />
                    <TextField
                      sx={{ mr: 3, width: '70%' }}
                      label="Изображение"
                      fullWidth
                      margin="normal"
                      name="img"
                      onChange={handleChange}
                      type="text"
                      value={values.img}
                      variant="outlined"
                      error={errors.img}
                    />
                  </Box>
                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SeoPagesAdd;
