import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useGet, usePut, httpDelete } from '../../API/request';

export const BeforeAfterRow = ({ row, showAlert, handleOpen, openId }) => {
  const [uploadedBefore, setUploadedBefore] = useState('');
  const [uploadedAfter, setUploadedAfter] = useState('');

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [values, setValues] = useState({ before_img: null, after_img: null });

  const [title, setTitle] = useState(row.title);
  const [changeTitle, setChangeTitle] = useState(false);
  const [changeAfterImage, setChangeAfterImage] = useState(false);
  const [changeBeforeImage, setChangeBeforeImage] = useState(false);

  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);

  const putU = usePut();

  const openRow = () => {
    handleOpen(row.id);
  };

  const open = openId === row.id;


  useEffect(() => {
    const before_img = row.before_img
      ? `${process.env.REACT_APP_API_URL}public/uploads/images/${row.before_img}`
      : '';
    const after_img = row.after_img
      ? `${process.env.REACT_APP_API_URL}public/uploads/images/${row.after_img}`
      : '';

    setUploadedBefore(before_img);
    setUploadedAfter(after_img);

    // проверка наличия заголовка в localStorage при загрузке страницы
    // const savedTitle = localStorage.getItem(`title_${row.id}`);

    // if (savedTitle) {
    //     setTitle(savedTitle);
    // } else {
    //     setTitle(row.title);
    // }

    // httpDelete(`specialist/before_after/8`)
  }, []);

  const updateTitle = () => {
    setSubmitDisabled(true);

    let data = new FormData();

    if (changeTitle) {
      data.append('title', title);
    }

    putU(`specialist/before_after/${row.id}`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert(5, 'success', 'Заголовок успешно обновлен');
          setNotificationSeverity('success');
          setNotificationMessage(
            'Заголовок и изображение после успешно обновлены'
          );
          setNotificationOpen(true);
          if (changeTitle) {
            setChangeTitle(false);
          }
        } else {
          showAlert(5, 'error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert(5, 'error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  }

  // функция для обновления заголовка
  const handleTitleChange = (event) => {
    if (!open) return;
    setTitle(event.target.value);
    if (!changeTitle) {
      setChangeTitle(true);
    }
    // Сохранение заголовка в localStorage
    // localStorage.setItem(`title_${row.id}`, event.target.value);
  };

  const submitBefore = () => {
    setSubmitDisabled(true);

    let data = new FormData();
    data.append('img', values.before_img);
   

    putU(`specialist/before_after/before/${row.id}`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert(5, 'success', 'Данные успешно обновлены');
          setNotificationSeverity('success');
          setNotificationMessage(
            'Заголовок и изображение до успешно обновлены'
          );
          setChangeBeforeImage(false);
          setNotificationOpen(true);
          if (changeTitle) {
            setChangeTitle(false);
          }
        } else {
          showAlert(5, 'error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert(5, 'error', 'Ошибка сервера');
      })
      .finally(() => {
        console.log('submitBefore');
        setSubmitDisabled(false);
      });
  };

  const submitAfter = () => {
    setSubmitDisabled(true);

    let data = new FormData();
    data.append('img', values.after_img);

    putU(`specialist/before_after/after/${row.id}`, data)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert(5, 'success', 'Данные успешно обновлены');
          setNotificationSeverity('success');
          setNotificationMessage(
            'Заголовок и изображение после успешно обновлены'
          );
          setChangeAfterImage(false);
          setNotificationOpen(true);
          if (changeTitle) {
            setChangeTitle(false);
          }
        } else {
          showAlert(5, 'error', 'Ошибка');
        }
      })
      .catch((err) => {
        showAlert(5, 'error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submit = async () => {
    const isUpdateBefore =
      values.before_img && values.before_img instanceof File;
    const isUpdateAfter = values.after_img && values.after_img instanceof File;

    if (!changeBeforeImage && !changeAfterImage && !changeTitle) {
      return showAlert(5, 'error', 'Нет изменений');
    }

    if (isUpdateBefore && changeBeforeImage) {
      submitBefore();
    }
    if (isUpdateAfter && changeAfterImage) {
      submitAfter();
    }

    if (changeTitle) {
      updateTitle();
    }
  };

  const beforeImgUploaded = (event) => {
    // setNothingChanged(false);
    setUploadedBefore(URL.createObjectURL(event.target.files[0]));
    setValues({ ...values, before_img: event.target.files[0] });
    setChangeBeforeImage(true);
    // Если заголовок изменился, обновляем состояние заголовка
    // if (event.target.value !== title) {
    //     setTitle(event.target.value);
    //     localStorage.setItem(`title_${row.id}`, event.target.value);
    // }
  };

  const afterImgUploaded = (event) => {
    // setNothingChanged(false);
    setUploadedAfter(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      after_img: event.target.files[0]
    });
    setChangeAfterImage(true);
    // Если заголовок изменился, обновляем состояние заголовка
    // if (event.target.value !== title) {
    //     setTitle(event.target.value);
    //     localStorage.setItem(`title_${row.id}`, event.target.value);
    // }
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => openRow()}
          >
            {open ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell component="th" scope="row">
          {/* использование TextField для редактирования заголовка */}
          <TextField 
          value={title} 
          onChange={handleTitleChange} 
          fullWidth 
          onMouseDown={(e) => !open && e.preventDefault()}
          />
        </TableCell>
        <TableCell>
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <Avatar src={uploadedBefore} />
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <Avatar src={uploadedAfter} />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Редактирование фотографий
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <div className="itemWrapper">
                  Фото до
                  <div className="container">
                    <input
                      accept="xlsx/*"
                      type="file"
                      style={{ display: 'none' }}
                      id={3}
                      onChange={(event) => beforeImgUploaded(event, 1)}
                    />
                    <label htmlFor={3}>
                      <img src={uploadedBefore} className="beforeAfterImg" />
                      <div className="middle" />
                    </label>
                  </div>
                  <div className="help-text">
                    Доступны следующие расширения: .png .jpg .svg .bmp .tga
                    .webp
                  </div>
                  {/* <Button
                    color="primary"
                    variant="contained"
                    onClick={submitBefore}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button> */}
                </div>
                <div className="itemWrapper">
                  Фото после
                  <div className="container">
                    <input
                      accept="xlsx/*"
                      type="file"
                      style={{ display: 'none' }}
                      id={4}
                      onChange={(event) => afterImgUploaded(event, 1)}
                    />
                    <label htmlFor={4}>
                      <img src={uploadedAfter} className="beforeAfterImg" />
                      <div className="middle" />
                    </label>
                  </div>
                  <div className="help-text">
                    Доступны следующие расширения: .png .jpg .svg .bmp .tga
                    .webp
                  </div>
                  {/* <Button
                    color="primary"
                    variant="contained"
                    onClick={submitAfter}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button> */}
                </div>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={submit}
                disabled={submitDisabled}
              >
                Сохранить
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
